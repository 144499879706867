import React from 'react';

export default function Header() {


    return (
        <div className='header-content'>

        </div>
    )
}
